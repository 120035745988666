import React from 'react'
import Layout from '../components/Layout'
import Posts from '../components/Posts'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

const CategoryTemplate = props => {
  const {
    data: {
      categories: { nodes: posts }, // alias categories を使った．
    },
  } = props
  const {
    pageContext: { category }, // pageContext の key に自動的に入っている．
  } = props
  return (
    <>
      <Layout>
        <SEO title={`カテゴリ / ${category}`} />
        <Posts posts={posts} title={`${category}`} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetCategories($category: String) {
    categories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          author
          category
          slug
          date(formatString: "YYYY年MM月DD日")
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        id
      }
    }
  }
`

export default CategoryTemplate
